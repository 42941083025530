
import { defineComponent, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      detail: {
        name: '',
        picBank: '',
        note: '',
      },

      areaName: "",
    }
  },

  beforeMount() {
    this.getDocument();
  },

  methods: {
    getDocument() {

      const toast = useToast();
      const $route = useRoute();

      ApiService
        .getWithoutSlug('crmv2/main_legal/legal/legal/' + this.$route.params.id)
        .then((res) => {
          this.detail.name = res.data.name;
        })
        .catch((e) => {
          
          toast.error(e.res.data.detail)

        });
    },

  },

});
