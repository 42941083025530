
import { defineComponent, onMounted, reactive } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";

import moment from 'moment'

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      idSekolah: "",
    }
  },

  beforeMount() {
    this.getAllDocument();
  },

  methods: {

    getAllDocument() {

      const toast = useToast();
      const $route = useRoute();

      ApiService
        .getWithoutSlug('crmv2/main_legal/legal/legal/' + this.$route.params.id)
        .then((res) => {
          this.tableData = res.data.documentLegality;
          this.idSekolah = res.data._id;
        })
        .catch((e) => {
          toast.error(e.res.data.detail)
        });
    },

    download(index, item){
      const toast = useToast();
      ApiService
        .getWithoutSlug('crmv2/main_legal/legal/legal/download_document_legality/' + this.idSekolah + '/' + item.id)
        .then((res) => {
        })
        .catch((e) => {
          toast.error(e.res.data.detail)
        });

    },

    handleDelete(index, item) {
      Swal
        .fire({
          title: "Apakah Anda Yakin ?",
          text: `Data yang telah dihapus tidak dapat dikembalikan !`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Ya, hapus !",
          cancelButtonText: "Batal",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(item);
          }
        });
    },
    deleteRow(item) {

      const toast = useToast();

      ApiService
        .delete('crmv2/main_legal/legal/legal/' + this.idSekolah + '/' + item.id)
        .then((res) => {
          toast.success("Hapus Berhasil")
          this.getAllDocument();
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
        });
    },

    getMoment : function (date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
  },

});
